
import { OptionSelect } from './OptionSelect';
import { MatureRadio } from './MatureRadio';
import { InputAgeField } from './InputAgeField';
// import { PurbertyField } from './PurbertyField';

const Forms = ()=>{
    return(
        <>
            <section  className="mt-10 egg-count form w-10/12 p-4 m-auto border-2 border-solid border-greys"> 
                <section className='flex m-auto w-2/3 border-solid border-primary border-4'>
                    <article className='border mr-4 pl-4 pr-4 border-solid rounded-lg bg-offwhite'>
                        <MatureRadio />
                    </article>
                    <article className='border pl-4 pr-4 border-solid rounded-lg bg-offwhite'>
                        <OptionSelect />
                    </article>
                    {/* <PurbertyField /> */}
                </section>
               
                <section className=''>
                    <InputAgeField /> 
                </section>
            </section>   
        </>
         
    );
}

export default Forms;